<template>
    <section class="work-order-management" @click.stop="pickerclick" style="height: 90vh;overflow-y: auto;">
        <div class="top-bar bg-white">
            <el-radio-group style="color: black;width: 100% !important; margin-bottom:10px"  v-model="currentTab" @change="handleTabChange">
                    <el-radio-button :label="1">
                        <el-badge :value="all">
                            <div style="width: 100px;">
                                房源退换工单
                            </div>
                        </el-badge>
                    </el-radio-button>
                    <el-radio-button :label="2">
                        <el-badge :value="expire">
                          <div style="width: 100px;">
                            押金转移工单
                          </div>
                        </el-badge>
                    </el-radio-button>
                </el-radio-group>
        </div>
        <!-- 房源退换工单 -->
        <div v-if="currentTab === 1">
            <div class="top-bar bg-white">
                <el-input v-model="formSearch.wordNum" placeholder="请输入工单编号" style="margin: 0 7px; width: 200px"
                        size="small" clearable/>
                <el-input v-model="formSearch.name" placeholder="请输入房间地址/租客名字" style="margin: 0 7px; width: 200px" size="small"
                        clearable/>
                <el-date-picker  @change="pickerclickitr"    ref="pickerRef"  v-model="startAndEndDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                                style="margin: 0 7px;" end-placeholder="结束日期" size="small" clearable/>
                <el-select ref="selectstatus" v-model="formSearch.status" size="small" placeholder="请选择业务类型" clearable
                            style="margin-right: 10px; width: 200px">
                        <el-option label="退房" value="退房"/>
                        <el-option label="换房" value="换房"/>
                    </el-select>

                    <el-select ref="stateRef"  v-model="formSearch.state" size="small" placeholder="请选择状态" clearable
                            style="margin-right: 10px; width: 200px">

                        <el-option v-for="(item,index) in stateList" :value="item.code" :label="item.codeName" :key="index"/>
                    </el-select>
                <el-select ref="districtRef"  v-model="formSearch.district" clearable filterable allow-create default-first-option size="small"
                        placeholder="请选择区域">
                    <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"/>
                </el-select>
                <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
                <el-button @click="handleReset" size="small" style="margin: 0 7px;">重置</el-button>
                <el-button type="primary" size="small" icon="el-icon-download" @click="wordExport">导出</el-button>
            </div>
            <r-e-table class="bg-white"   :key="currentTab" ref="orderTableRef" :dataRequest="getWordListTableData" :columns="tableColumn"
                    :query="formSearch" tooltip-effect="light" :height="790" :row-style="rowStyle"
                    :cell-style="cellStyle">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <div class="table-tools" v-if="row.wordType==='换房'">
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')"
                                v-if="row.state===2 && getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')">确认</span>
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'chakan')"
                                v-if="row.state===2 && !getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')">查看</span>
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'xinqian')"
                                v-if="row.state===3 && getButtonMenu('WORKORDER_XINQIAN_BTUUON')">新签</span>
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'chakan')"
                                v-if="row.state===3 && !getButtonMenu('WORKORDER_XINQIAN_BTUUON')">查看</span>
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'chakan')"
                                v-if="row.state===6">查看</span>

                        </div>

                        <div class="table-tools" v-if="row.wordType==='退房'">
                            <span class="table-btn" @click="openDialog(getButtonStateTuifang(row).module,row)">
                                {{ getButtonStateTuifang(row).buttonName }}
                            </span>
                        <span class="table-btn" v-if="row.state === 1 && getButtonMenu('CHEHUIGONGDAN')"  @click="openDialog('dialogCheckOutWorkOrderView',row,true)">
                                撤回
                            </span>

                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <!-- 押金转移工单 -->
        <div  v-else-if="currentTab === 2">
            <div class="top-bar bg-white">
                <el-input v-model="TransferFormSearch.workOrderNum" placeholder="请输入工单编号" style="margin: 0 7px; width: 200px"
                        size="small" clearable/>
                <el-input v-model="TransferFormSearch.leasorName" placeholder="请输入租客名字/申请人" style="margin: 0 7px; width: 200px" size="small"
                        clearable/>
                <el-date-picker  @change="pickerclickitr"    ref="pickerRef"  v-model="TransferStartAndEndDate" type="daterange" range-separator="至" start-placeholder="申请开始时间"
                                style="margin: 0 7px;" end-placeholder="申请结束时间" size="small" clearable/>
                <el-select ref="stateRef"  v-model="TransferFormSearch.status" size="small" placeholder="请选择处理状态" clearable
                        style="margin-right: 10px; width: 200px">

                        <el-option label="待发起" :value="0"/>
                        <el-option label="待片管员预审" :value="1"/>
                        <el-option label="待负责人审核" :value="2"/>
                        <el-option label="待财务部审核" :value="3"/>
                        <el-option label="已完成" :value="4"/>
                        <el-option label="已作废" :value="10"/>
                </el-select>
                <el-button type="primary" size="small" style="margin: 0 7px;" @click="transferHandleSearch">查询</el-button>
                <el-button @click="transferHandleReset" size="small" style="margin: 0 7px;">重置</el-button>
                <el-button type="primary" size="small" icon="el-icon-download" @click="transferwordExport">导出</el-button>
            </div>
            <r-e-table class="bg-white"  :key="currentTab" ref="orderTransferTableRef" :dataRequest="getTransferWordListTableData" :columns="tableColumns"
                    :query="TransferFormSearch" tooltip-effect="light" :height="790" :row-style="rowStyles"
                    :cell-style="cellStyles">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <div class="table-tools" >
                            <span class="table-btn" @click="openDialog(getButtonStateDeposit_transfer(row).module,row)">
                                {{ getButtonStateDeposit_transfer(row).buttonName }}
                            </span>
                        <span class="table-btn" v-if="row.status === 1 && getButtonMenu('DEPOSIWITHDRAW')"  @click="openDialog('dialogTransferWorkOrderView',row,true)">
                                撤回
                            </span>

                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <dialog-check-out ref="dialogCheckOut" @handleSearch="handleSearch"/>

        <!--退房工单查看-->
        <dialog-check-out-work-order-view ref="dialogCheckOutWorkOrderView" @handleSearch="handleSearch"/>
        <!--退房工单处理-->
        <dialog-check-out-work-order-handle ref="dialogCheckOutWorkOrderHandle" @handleSearch="handleSearch"/>
        <!-- 押金转移工单处理 -->
        <dialog-transfer-work-order-handle ref="dialogTransferWorkOrderHandle" @transferHandleSearch="transferHandleSearch"/>
        <dialog-transfer-work-order-view ref="dialogTransferWorkOrderView" @transferHandleSearch="transferHandleSearch"/>

        <!--换房工单处理-->
        <dialog-huanfang ref="dialogHuanfang" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
import {getAddressList} from "@/api/community-management";
import {tableWorkOrderColumn,tableTransferWorkOrderColumn} from "./data";
import {getWordList, wordExport,getTransferWordList,transferwordExport} from "@/api/work-order-management";
import {timeFormat} from "@custom/index";
import {downloadByData} from "@/utils/hooks/download";
import {buttonCookies} from "@storage/cookies";

export default {
    name: "work-order-management",
    data() {
        return {
            itr:true,
            tableColumn: tableWorkOrderColumn(this),

            formSearch: {
                wordNum: null,
                name: null,
                status: null,
                state: null,
                district: null,
            },
            startAndEndDate: [],
            stateList: [],
            tfStateList: [
                {code: -1, codeName: '全部',},
                {code: 0, codeName: '待发起',},
                {code: 1, codeName: '待片管员预审',},
                {code: 2, codeName: '待负责人审核',},
                {code: 3, codeName: '待财务审核',},
                {code: 4, codeName: '已完成',},
            ],
            hfStateList: [
                {code: -1, codeName: '全部',},
                {code: 2, codeName: '待确认',},
                {code: 3, codeName: '待处理',},
                {code: 6, codeName: '已完成',},
            ],
            options: [], //所属区域下拉数据存放
            currentTab: 1, // 默认展示房源退换工单
            // 押金转移工单
            tableColumns: tableTransferWorkOrderColumn(this),
            TransferFormSearch: {
                workOrderNum: null, //工单编号
                leasorName: null, //租户名 提交人名称
                status: null, //工单状态
            },
            TransferStartAndEndDate: [],
        };
    },
    async mounted() {
        //选择区域接口请求
        let res = await getAddressList();
        this.options = res.list;
    },
    components: {
        dialogCheckOut: () => import("@/views/rental-management/house-resource/components/part/components/dialog-check-out"),
        dialogCheckOutWorkOrderView: () => import("./components/dialog-check-out-work-order-view"),
        dialogCheckOutWorkOrderHandle: () => import("./components/dialog-check-out-work-order-handle"),
        dialogTransferWorkOrderHandle:()=> import("./components//dialog-transfer-work-order-handle"),
        dialogTransferWorkOrderView:()=> import("./components//dialog-transfer-work-order-view"),
        dialogHuanfang: () => import("./components/dialog-huanfang"),
    },
  deactivated(){
    // alert('离开')
    // 2024-7-10-陈守亮处理切换页面关闭组件
    this.pickerclick()
  },
    methods: {
        getWordListTableData(params) {
            return getWordList(params);
        },
        handleSearch() {
            this.$refs["orderTableRef"].getTableData(true);
        },
        handleReset() {
            this.startAndEndDate = [];
            this.formSearch = {wordNum: null, name: null, status: null, state: null};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openDialog(openName = "", data, openDialog) {
          console.log(data)
          // alert('999')
          // alert(openName,'11')
            if (openName) this.$refs[openName].openDialog(data,openDialog);
        },
        wordExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let {formSearch} = this;
            let paramsData = {...formSearch}
            wordExport(paramsData).then(res => {
                downloadByData({data: res, filename: `工单管理${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                loading.close();
            }).catch(err => {
                loading.close();
            });
        },

        rowStyle({row, rowIndex}) {
            let {state, wordType} = row;
            if (state === 2) return {backgroundColor: '#FFF5F5'};
            if (state === 6) return {backgroundColor: '#E5EFFF'};
        },
        // rowStyles({row, rowIndex}) {
        //     let {status, wordType} = row;
        //     if (status === 0) return {backgroundColor: '#FFF5F5'};
        //     if (status === 6) return {backgroundColor: '#E5EFFF'};
        // },

        cellStyle({row, column, rowIndex, columnIndex}) {
            let {state} = row;
            if (state === 2 && columnIndex === 1) return {color: '#FF3718'};
            if (state === 3 && columnIndex === 1) return {color: '#FF3718'};
            if (state === 6 && columnIndex === 1) return {color: '#1890FF'};
        },
        cellStyles({row, column, rowIndex, columnIndex}) {
            let {status} = row;
            if (status === 0 && columnIndex === 1) return {color: '#FF3718'};
            if (status === 1 && columnIndex === 1) return {color: '#FF3718'};
            if (status === 2 && columnIndex === 1) return {color: '#FF3718'};
            if (status === 3 && columnIndex === 1) return {color: '#FF3718'};
        },


        getButtonMenu(menu) {
            return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
        },
        //退房操作按钮
        getButtonStateTuifang(row) {
            const {state} = row;
            if (state === 0) {
                return {buttonName: '发起', module: 'dialogCheckOutWorkOrderHandle'}
            } else if (state === 1 && this.getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')) {
                return {buttonName: '预审', module: 'dialogCheckOutWorkOrderHandle'}
            } else if (state === 2 && this.getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')) {
                return {buttonName: '审核', module: 'dialogCheckOutWorkOrderHandle'}
            } else if (state === 3 && this.getButtonMenu('WORKORDER_CHECKOUT_HANDLE_BUTTON')) {
                return {buttonName: '审核', module: 'dialogCheckOutWorkOrderHandle'}
            } else {
                return {buttonName: '查看', module: 'dialogCheckOutWorkOrderView'}
            }
        },
      // 2024-4-16-新增点击空白关闭组件
      pickerclick(e){
        console.log( this.itr)
        this.itr = !this.itr;
        if(this.itr){
          this.$refs['pickerRef'].hidePicker();
        }
        this.$refs['selectstatus'].blur();
        this.$refs['stateRef'].blur();
        this.$refs['districtRef'].blur();
        console.log(this.itr)
      },
      pickerclickitr(){
        this.itr = true;
        console.log(this.itr)
      },
     //2024/08/29 张晓瑜切换按钮更换工单列表
        handleTabChange(value) {
            this.currentTab = value;
            this.$nextTick(() => {
                if (value === 1) {
                    this.getWordListTableData();
                } else if (value === 2) {
                    this.getTransferWordListTableData();
                }
            });
        },
        //押金转移工单
        getTransferWordListTableData(params) {
            return getTransferWordList(params);
        },
       // 押金转移工单搜索
        transferHandleSearch() {
            this.$refs["orderTransferTableRef"].getTableData(true);
        },
        // 押金转移工单重置
        transferHandleReset() {
            this.TransferStartAndEndDate = [];
            this.TransferFormSearch = {workOrderNum: null, leasorName: null, status: null,};
            Promise.resolve(this.TransferFormSearch).then(() => this.transferHandleSearch());
        },
        // 押金转移工单导出
        transferwordExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let {TransferFormSearch} = this;
            let paramsData = {...TransferFormSearch}
            transferwordExport(paramsData).then(res => {
                downloadByData({data: res, filename: `工单管理${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                loading.close();
            }).catch(err => {
                loading.close();
            });
        },
        //  押金转移工单操作按钮
        getButtonStateDeposit_transfer(row) {
            const {status} = row;
               if (status === 0) {
                    return {buttonName: '发起', module: 'dialogTransferWorkOrderHandle'}
                }else if (status === 1 && this.getButtonMenu('DEPOSITFILMMANAGER')) {
                    return {buttonName: '预审', module: 'dialogTransferWorkOrderHandle'}
                } else if (status === 2 && this.getButtonMenu('DEPOSITRESPONSIBLE')) {
                    return {buttonName: '审核', module: 'dialogTransferWorkOrderHandle'}
                } else if (status === 3 && this.getButtonMenu('DEPOSITFINACEMMANAGER')) {
                    return {buttonName: '审核', module: 'dialogTransferWorkOrderHandle'}
                }  else{
                    return {buttonName: '查看', module: 'dialogTransferWorkOrderView'}
                }
        //         const {state} = row;
        //         if (state === 0) {
        //             return {buttonName: '发起', module: 'dialogCheckOutWorkOrderHandle'}
        //         } else if (state === 2 && this.getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')) {
        //             return {buttonName: '审核', module: 'dialogCheckOutWorkOrderHandle'}
        //         } else if (state === 3 && this.getButtonMenu('WORKORDER_CHECKOUT_HANDLE_BUTTON')) {
        //             return {buttonName: '审核', module: 'dialogCheckOutWorkOrderHandle'}
        //         } else {
        //             return {buttonName: '查看', module: 'dialogCheckOutWorkOrderView'}
        //         }
        },
    },
    watch: {
        startAndEndDate(value) {
            if (value) {
                let [startDate, endDate] = value;
                this.formSearch.startDate = timeFormat(startDate);
                this.formSearch.endDate = timeFormat(endDate);
            } else {
                this.formSearch.startDate = null;
                this.formSearch.endDate = null;
            }
        },
        TransferStartAndEndDate(value){
            if (value) {
                let [startDate, endDate] = value;
                this.TransferFormSearch.startDate = timeFormat(startDate);
                this.TransferFormSearch.endDate = timeFormat(endDate);
            } else {
                this.TransferFormSearch.startDate = null;
                this.TransferFormSearch.endDate = null;
            }
        },
        'formSearch.status'(value) {
            if (value) {
                if (value === '退房') {
                    this.stateList = this.tfStateList;
                    this.formSearch.state = null;
                }
                if (value === '换房') {
                    this.stateList = this.hfStateList;
                    this.formSearch.state = null;
                }
            } else {
                this.stateList = [];
                this.formSearch.state = null;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.work-order-management {
    padding: VH(25px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        height:93%;
        .el-table .cell {
            //white-space: nowrap;
        }
    }
}
</style>
